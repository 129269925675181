import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Announcement from "../components/Announcement";
import Navbar1 from "../components/Navbar1";

// Sample news data
const newsItems = [
  {
    id: 1,
    title: "ऐतिहासिक निर्णय : बारात में नहीं बजेगा डीजे, प्री-वेडिंग शूटिंग के सार्वजनिक प्रदर्शन पर भी रोक, विधवा मां दे सकेंगी अछरा",
    description: "दिल्लीवार कुर्मी क्षत्रिय समाज छत्तीसगढ़ के 54वें वार्षिक अधिवेशन में कई ऐतिहासिक निर्णय लिए गए। समाज में अब बारात में डीजे नहीं बजेंगे, चिकट केवल मामा पक्ष ही लाएंगे, प्री वेडिंग शूटिंग का भी सार्वजनिक प्रदर्शन नहीं कर सकेंगे।",
    img: "./image/news1110.jpg",
    url: "https://www.patrika.com/balod-news/dilliwar-kurmi-kshatriy-samaj-8720956",
    publishedAt: "2024-02-11T11:23:00",
  },
  {
    id: 2,
    title: "समाज से ही हमारी पहचान, सामाजिक नियमावली का हम सभी को करना चाहिए पालन",
    description: "दिल्लीवार कुर्मी क्षत्रिय समाज के 54वें वार्षिक अधिवेशन की शनिवार को शुरुआत हुई। प्रथम दिन विविध कार्यक्रम हुए। समाज के विकास को लेकर लोगों ने अपनी बातें रखी।",
    img: "./image/news3.jpg",
    url: "https://www.patrika.com/balod-news/dilliwar-kurmi-kshatriya-samaj-8719430",
    publishedAt: "2024-02-10T10:30:00",
  },
  // {
  //   id: 3,
  //   title: "दिल्लीवार कुर्मी क्षत्रिय समाज के 54वें वार्षिक अधिवेशन",
  //   description: "A leading tech company just released its latest flagship smartphone with cutting-edge features.",
  //   img: "./image/news3.jpg",
  //   url: "https://example.com/news3",
  //   publishedAt: "2024-09-07T08:45:00",
  // },
];

// Styled Components
const Container = styled.div``;

const Wrapper = styled.div`
  /* margin-top: 10%; */
  padding: 20px;
  background-color: #f5f5f5;
  /* min-height: 100vh; */
`;

const Title = styled.h1`
  text-align: center;
  font-size: 36px;
  color: #333;
  margin-bottom: 20px;
`;

const NewsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    margin-top:15%;
  }
`;

const NewsCard = styled.div`
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.03);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  }
`;

const NewsImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const NewsContent = styled.div`
  padding: 20px;
`;

const NewsTitle = styled.h2`
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
`;

const NewsTime = styled.p`
  font-size: 14px;
  color: #999;
  margin-bottom: 10px;
`;

const NewsDescription = styled.p`
  font-size: 16px;
  color: #666;
`;

const ReadMoreLink = styled.a`
  display: inline-block;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

const NewsList = () => {
  const [newsData, setNewsData] = useState(newsItems);

  return (
    <Container>
      <Wrapper>
        <Title>ताजा खबर</Title>
        <NewsWrapper>
          {newsData.map((news) => {
            const formattedDate = new Intl.DateTimeFormat('en-GB', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            }).format(new Date(news.publishedAt));

            return (
              <NewsCard key={news.id}>
                <NewsImage src={news.img} alt={news.title} />
                <NewsContent>
                  <NewsTitle>{news.title}</NewsTitle>
                  <NewsTime>{formattedDate}</NewsTime>
                  <NewsDescription>{news.description}</NewsDescription>
                  <ReadMoreLink href={news.url} target="_blank">
                    Read More
                  </ReadMoreLink>
                </NewsContent>
              </NewsCard>
            );
          })}
        </NewsWrapper>
      </Wrapper>
    </Container>
  );
};

export default NewsList;
