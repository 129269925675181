import { Facebook, Instagram, MailLockOutlined, Phone, Room, Twitter, WhatsApp } from "@mui/icons-material";
import styled from "styled-components";
import { mobile } from "../responsive";
import { Link } from 'react-router-dom';

const Container = styled.div`
  display:flex;
  ${mobile({
    flexDirection:"column"
  })}
  @media (max-width: 768px) {
    display:none;
  }
`;
const Left = styled.div`
  flex:1;
  display:flex;
  flex-direction: column;
  padding:20px;
`;

const Desc = styled.p`
  margin:20px 0px;
`;
const SocialContainer = styled.div`
  display:flex;
  margin-top: 20px;
`;

const SocialIcon = styled.div`
  width:40px;
  height:40px;
  border-radius:50%;
  color:white;
  background-color:#${props => props.color};
  display:flex;
  align-items:center;
  justify-content:center;
  margin-right:20px;
  cursor:pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;

  &:hover {
    transform: scale(1.2); /* Slightly enlarge on hover */
    background-color: #${props => lightenColor(props.color, 20)}; /* Lighter shade on hover */
  }
`;

const Center = styled.div`
  flex:1;
  padding:20px;
  ${mobile({
    display:"none"
  })}
`;
const Title = styled.h3`
  margin-bottom:30px;
`;

const List = styled.ul`
  margin:0;
  padding:0;
  list-style:none;
  display:flex;
  flex-wrap:wrap;
`;

const ListItem = styled.li`
  width:50%;
  margin-bottom:10px;
  cursor:pointer;
  &:hover {
    font-weight: bold;
  }
`;

const Right = styled.div`
  flex:1;
  padding:20px;
  ${mobile({
    backgroundColor:"lightgrey"
  })}
`;

const ContactItem = styled.div`
  margin-bottom:20px;
  display:flex;
  align-items:center;
`;

const Logo = styled.div`
  display:flex;
  img {
    height: 60px;
    @media (max-width: 488px) {
      height: 30px;
    }
  }
  h1{
    margin:10px;
  }
`;

const Footer = () => {
  return (
    <Container>
      <Left>
        <Logo>
          <img src="/image/kurmi.png" alt="kurmi Logo" />
          <h1> दिल्लीवार कुर्मी क्षत्रिय समाज </h1>
        </Logo>
        <Desc>
          लोकोक्ति के अनेक रूप उपलब्ध हैं, लेकिन अधिकांश में हास्य का समावेश करके या यादृच्छिक शब्द का प्रयोग करके परिवर्तन किया गया है।
        </Desc>
        <SocialContainer>
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <SocialIcon color="3B5999">
              <Facebook />
            </SocialIcon>
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <SocialIcon color="E4405F">
              <Instagram />
            </SocialIcon>
          </a>
          <a href="https://web.whatsapp.com/" target="_blank" rel="noopener noreferrer">
            <SocialIcon color="55ACEE">
              <WhatsApp />
            </SocialIcon>
          </a>
        </SocialContainer>
      </Left>
      <Center>
        <Title>Useful Links</Title>
        <List>
          <ListItem>
            <Link to="/" style={{ textDecoration: 'none',color:'black' }}>
              <ListItem>मुख पृष्ठ</ListItem>
            </Link>
          </ListItem>
          <ListItem>
            <Link to="/" style={{ textDecoration: 'none',color:'black' }}>
              <ListItem>लॉग इन</ListItem>
            </Link>
          </ListItem>
          <ListItem>जीवन परिचय</ListItem>
          <ListItem>रजिस्टर करे</ListItem>
          <ListItem>समाचार</ListItem>
          <ListItem>समाज न्यूज़</ListItem>
          <ListItem>फोटो गैलरी</ListItem>
        </List>
      </Center>
      <Right>
        <Title>Contact</Title>
        <ContactItem><Room style={{marginRight:"10px"}}/>Durg,(छ.ग.) - 49101</ContactItem>
        <ContactItem><Phone style={{marginRight:"10px"}}/> 8319677***</ContactItem>
        <ContactItem>
          <MailLockOutlined style={{marginRight:"10px"}}/>HamarDilliwarKurmiSamaj@gmail.com
        </ContactItem>
      </Right>
    </Container>
  )
}

export default Footer;

// Helper function to lighten the color for hover effect
const lightenColor = (color, percent) => {
  let num = parseInt(color, 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      G = (num >> 8 & 0x00FF) + amt,
      B = (num & 0x0000FF) + amt;

  return (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (G<255?G<1?0:G:255)*0x100 + (B<255?B<1?0:B:255)).toString(16).slice(1).toUpperCase();
};
