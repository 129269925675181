import { useState } from "react";
import styled from "styled-components";
import { publicRequest } from "../requestMethods";
import Announcement from "../components/Announcement";
import Navbar1 from "../components/Navbar1";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("https://images.tv9hindi.com/wp-content/uploads/2021/02/shivaji.jpg?w=1280")
      center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 40%;
  margin-top:10%;
  padding: 20px;
  background-color: white;

  @media (max-width: 488px) {
    margin-top: 30%;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 20px 10px 0px 0px;
  padding: 10px;
`;

const Agreement = styled.span`
  font-size: 12px;
  margin: 20px 0px;
`;

const Button = styled.button`
  width: 40%;
  border: none;
  padding: 15px 20px;
  background-color: #cf4d23;
  color: white;
  cursor: pointer;
`;

const Register = () => {
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [profession, setProfession] = useState("");
  const [error, setError] = useState(false);

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const res = await publicRequest.post("/auth/register", {
        username,
        firstName,
        lastName,
        phone,
        email,
        age,
        profession,
      });
      res.data && window.location.replace("/login");
    } catch (err) {
      setError(true);
    }
  };

  return (
    <Container>
      <Announcement />
      <Navbar1 />
      <Wrapper>
        <Title>समाज में जुड़े</Title>
        <Form>
          {/* <Input
            placeholder="उपयोगकर्ता नाम"
            onChange={(e) => setUsername(e.target.value)}
          /> */}
          <Input
            placeholder="पहला नाम"
            onChange={(e) => setFirstName(e.target.value)}
          />
          <Input
            placeholder="अंतिम नाम"
            onChange={(e) => setLastName(e.target.value)}
          />
          <Input
            placeholder="फ़ोन नंबर"
            onChange={(e) => setPhone(e.target.value)}
          />
          <Input
            placeholder="ईमेल"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            placeholder="आयु"
            type="number"
            onChange={(e) => setAge(e.target.value)}
          />
          <Input
            placeholder="व्यवसाय"
            onChange={(e) => setProfession(e.target.value)}
          />
          <Agreement>
            खाता बनाने से, मैं सहमति देता हूँ कि मेरी व्यक्तिगत जानकारी <b>गोपनीयता नीति</b> के अनुसार संसाधित की जाएगी।
          </Agreement>
          <Button onClick={handleClick}>जुड़े</Button>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default Register;
