import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Announcement from "../components/Announcement";
import Navbar1 from "../components/Navbar1";
import NewsList from "../components/NewsList";

// Sample news data
const newsItems = [
  {
    id: 1,
    title: "ऐतिहासिक निर्णय : बारात में नहीं बजेगा डीजे, प्री-वेडिंग शूटिंग के सार्वजनिक प्रदर्शन पर भी रोक, विधवा मां दे सकेंगी अछरा",
    description: "The stock market reached an all-time high today, with major indices gaining significant value.",
    img: "./image/kurmi1.jpg",
    url: "https://example.com/news1",
    publishedAt: "2024-09-09T10:00:00",
  },
  {
    id: 2,
    title: "दिल्लीवार कुर्मी क्षत्रिय समाज के 54वें वार्षिक अधिवेशन",
    description: "The local football team secured a thrilling victory in the national championship.",
    img: "./image/k4.jpg",
    url: "https://example.com/news2",
    publishedAt: "2024-09-08T15:30:00",
  },
  {
    id: 3,
    title: "दिल्लीवार कुर्मी क्षत्रिय समाज के 54वें वार्षिक अधिवेशन",
    description: "A leading tech company just released its latest flagship smartphone with cutting-edge features.",
    img: "./image/news3.jpg",
    url: "https://example.com/news3",
    publishedAt: "2024-09-07T08:45:00",
  },
];

// Styled Components
const Container = styled.div`
margin-top:11%;

@media (max-width: 768px) {
    margin-top: 40%;
  }
`;



const News = () => {
  const [newsData, setNewsData] = useState(newsItems);

  return (
    <div>
      <Announcement />
      <Navbar1 />
      <Container>
        <NewsList/>
      </Container>
    </div>
  );
};

export default News;
