import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Navbar1 from "../components/Navbar1";
import Announcement from "../components/Announcement";

const Container = styled.div`
`;

const PhotoCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  background-color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05); /* Slightly enlarges the card */
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2); /* Deeper shadow effect */
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  margin-top: 15%;
`;

const PhotoImage = styled.img`
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease; /* Adds smooth zoom effect */

  ${PhotoCard}:hover & {
    transform: scale(1.1); /* Slightly zooms the image on hover */
  }
`;

const PhotoTitle = styled.h3`
  margin-top: 10px;
  font-size: 18px;
`;

const PhotoGallery = () => {
  const navigate = useNavigate();

  // Hardcoded test data
  const testPhotos = [
    {
      id: 1,
      title: "54वें वार्षिक अधिवेशन",
      img: "./image/54वें वार्षिक अधिवेशन.jpg",
    },
    {
      id: 2,
      title: "53वाँ वार्षिक अधिवेशन",
      img: "./image/124.jpg",
    },
    {
      id: 3,
      title: "51वां अधिवेशन",
      img: "./image/125.jpg",
    },
    // {
    //     id: 4,
    //     title: "Mountains",
    //     img: "./image/mountain.jpg",
    //   },
    //   {
    //     id: 5,
    //     title: "Ocean",
    //     img: "./image/ocean.jpg",
    //   },
    //   {
    //     id: 6,
    //     title: "Forest",
    //     img: "./image/forest.jpg",
    //   },
  ];

  const handlePhotoClick = (title) => {
    navigate(`/gallery/${title}`);
  };

  return (
    <Container>
      <Announcement />
      <Navbar1 />
      <Wrapper>
        {testPhotos.map((photo) => (
          <PhotoCard key={photo.id} onClick={() => handlePhotoClick(photo.title)}>
            <PhotoImage src={photo.img} alt={photo.title} />
            <PhotoTitle>{photo.title}</PhotoTitle>
          </PhotoCard>
        ))}
      </Wrapper>
    </Container>
  );
};

export default PhotoGallery;
