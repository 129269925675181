import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Container = styled.section`
  margin: 10px 100px;
  padding: 60px 0;
  background: linear-gradient(135deg, #fff, #f9f9f9);

  @media (max-width: 768px) {
    margin: 10px 20px;
    padding: 40px 0;
  }

  @media (max-width: 480px) {
    margin: 10px;
    padding: 30px 0;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Column = styled.div`
  flex: ${(props) => (props.size ? props.size : "1")};
  padding: 15px;
  animation: fadeIn 1s ease-in-out;

  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }

  @media (max-width: 768px) {
    flex: 100%;
    padding: 10px 0;
  }
`;

const SectionTitle = styled.div`
  text-align: center;
  margin-bottom: 50px;

  h2 {
    font-size: 42px;
    font-weight: bold;
    color: #333;
    position: relative;
    display: inline-block;
    padding-bottom: 10px;

    &::after {
      content: '';
      width: 60px;
      height: 3px;
      background-color: #FFA500;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    span {
      color: #FFA500;
    }

    @media (max-width: 768px) {
      font-size: 32px;
    }

    @media (max-width: 480px) {
      font-size: 28px;
    }
  }
`;

const ImageBox = styled.div`
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 10px;

  img {
    width: 100%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const Content = styled.div`
  text-align: left;
  padding-left: 20px;

  p {
    font-size: 18px;
    color: #666;
    line-height: 1.8;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      font-size: 16px;
    }

    @media (max-width: 480px) {
      font-size: 14px;
    }
  }

  @media (max-width: 768px) {
    padding-left: 0;
    text-align: center;
  }
`;

const StyledButton = styled(Link)`
  display: inline-block;
  margin-top: 20px;
  padding: 12px 30px;
  background-color: #FFA500;
  color: white;
  text-decoration: none;
  font-size: 16px;
  border-radius: 25px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    background-color: #e69500;
    transform: translateY(-2px);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    padding: 8px 15px;
    font-size: 12px;
  }
`;

const AboutSection = () => {
  return (
    <Container className="about sec-padd">
      <Row>
        <Column size="12">
          <SectionTitle className="section-title center">
            <h2>
              दिल्लीवार कुर्मी क्षत्रिय समाज <span className="thm-color">में आपका स्वागत है</span>
            </h2>
          </SectionTitle>
        </Column>
      </Row>

      <Row>
        <Column size="5">
          <ImageBox className="img-box">
            <img
              src="./image/abt.jpg"
              alt="Chhattisgarh Kurmi Samaj"
              title="Chhattisgarh Kurmi Samaj"
            />
          </ImageBox>
        </Column>

        <Column size="7">
          <Content>
            <p>
              <strong>दिल्लीवार कुर्मी क्षत्रिय समाज [संक्षिप्त परिचय]</strong>
            </p>
            {/* <p>
            दिल्लीवार कुर्मी क्षत्रिय समाज का गठन वर्ष 2006 में किया गया। छ.ग. राज्य के प्रथम पड़ा है, हास्य डालना या क्रमरहित शब्द , जो तनिक भी विश्वसनीय नहीं लग रहे हो. यदि आप Lorem Ipsum के एक अनुच्छेद का उपयोग करने जा रहे हैं, तो आप को यकीन दिला दें कि पाठ के मध्य में वहाँ कुछ भी शर्मनाक छिपा हुआ नहीं है. इंटरनेट पर सभी Lorem Ipsum जनरेटर पूर्वनिर्धारित मात्रा में अनुच्छेद को दोहराने कि वजह से इंटरनेट पर सबसे विश्वसनीय जनरेटर होने के लिए जाने जाते हैं. यह एक 200 से भी अधिक लैटिन शब्दों के शब्दकोश का उपयोग करता है , जो एक मुट्ठी भर मॉडल वाक्य संरचना के साथ संयुक्त होकर Lorem Ipsum उत्पन्न करता है जो उचित भी लगता है. इसलिए उत्पन्न Lorem Ipsum हमेशा पुनरावृत्ति, हास्य, या गैर विशेषता शब्दों आदि से मुक्त होता है.
            </p> */}
           <p>दिल्लीवार कुर्मी छत्तीसगढ़ के प्रमुख कुर्मी उपजातियों में से एक हैं, जो मुख्यतः दुर्ग, बालोद, राजनांदगांव और रायपुर जिलों के लगभग 240 गांवों में निवासरत हैं। दिल्लीवार कुर्मियों में प्रचलित उपनामों में देशमुख, बेलचंदन, पिपरिया, सुकतेल, महतेल, हरमुख, गौतम और वर्मा प्रमुख हैं। इनके गोत्र मुख्यतः भारद्वाज, कौशिक और कश्यप होते हैं। </p>

           {/* <p>  इस समाज की अनुमानित जनसंख्या लगभग 1 लाख 70 हजार है, जो छत्तीसगढ़ के विभिन्न नगरों और ग्रामीण क्षेत्रों में फैली हुई है। दिल्लीवार कुर्मी समाज में भी अन्य कुर्मी उपजातियों की तरह अंतर्जातीय विवाह की प्रथा प्रचलित है, जो इस समुदाय की एकता और आपसी सहयोग की भावना को मजबूत करता है। </p> */}

            <p> समाज के वर्तमान केंद्रीय अध्यक्ष श्री डॉ. राजेंद्र हरमुख जी हैं और महिला समाज की केंद्रीय अध्यक्ष श्रीमती प्रीति देशमुख जी एवं युवा केंद्रीय अध्यक्ष श्री योगेश्वर देशमुख जी हैं। </p>
            <StyledButton to="/about-us">और पढ़ें</StyledButton>
          </Content>
        </Column>
      </Row>
    </Container>
  );
};

export default AboutSection;
