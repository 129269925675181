import styled from "styled-components";
import { Email, Facebook, Instagram, Person, Phone, WhatsApp, Work } from "@mui/icons-material";

const Container = styled.div`
  height: 100px;
  width: 100%;
  background-color: #cf4d23;
  color: white;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  z-index: 1001;
  position: fixed;
  top: 0;

  @media (max-width: 488px) {
    position:absolute;
    height: 90px;
  }
`;
const Wrapper = styled.div`
  padding: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 488px) {
    padding: 10px 0px;
    display: inline-block;
  }
`;

const SocialContainer = styled.div`
  display: flex;
  @media (max-width: 488px) {
   display:none;
  }
`;

const SocialIcon = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: white;
  background-color: #${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.2);
  }
`;


const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const Contact = styled.div`
  /* display: flex; */
  align-items: center;
  margin-right: 15px;
  margin-left:10px;

  a {
    color: white;
    text-decoration: none;
    margin-right: 15px;
    display: flex;
    align-items: center;
    font-size: 14px;
    transition: color 0.3s ease;

    &:hover {
      color: #ddd;
    }
  }

  svg {
    font-size:20px;
    margin-right: 5px;
    transition: color 0.3s ease;

    &:hover {
      color: #ddd;
    }
  }
`;

const Title = styled.span`
  margin-left: 15px;
  color: white;
  font-weight: bold;
  font-size: 34px;

  @media (max-width: 488px) {
    font-size: 23px;
    }
`;


const Logo = styled.div`
  img {
    height: 70px;
    border-radius: 5px;

    @media (max-width: 488px) {
      height: 70px;
      margin-left:10px;
    }
  }
`;

const Center = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 488px) {
    display: none;
    flex: 2;
    justify-content: center;
    margin-right: 10px;
  }
`;

const ProfileImage = styled.img`
  width: 77px;
  height: 77px;
  border-radius: 25%;
  margin-left: 15px;

  @media (max-width: 488px) {
    width: 40px;
    height: 40px;
  }
`;

const Announcement = () => {
  return (
    <Container>
      <Wrapper>
        {/* Left Section - Social Icons */}
        <Left>
          {/* <SocialContainer>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <SocialIcon color="3B5999">
                <Facebook />
              </SocialIcon>
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <SocialIcon color="E4405F">
                <Instagram />
              </SocialIcon>
            </a>
            <a href="https://web.whatsapp.com/" target="_blank" rel="noopener noreferrer">
              <SocialIcon color="55ACEE">
                <WhatsApp />
              </SocialIcon>
            </a>
          </SocialContainer> */}
        </Left>

        {/* Center Section - Logo and Title */}
        <Center>
          <Logo>
            <img src="/image/kurmi.png" alt="kurmi samaaj Logo" />
          </Logo>
          <Title>दिल्लीवार कुर्मी क्षत्रिय समाज</Title>
        </Center>

        {/* Right Section - Contact Details and Profile Image */}
        <Right>
          <ProfileImage src="/image/profile.jpg" alt="Profile Image" /> {/* Profile Image */}
          <Contact>
            <a href="tel:9899757783">
              <Phone />
               +91 9899757783
            </a>
            <a>
              <Person/>
               हेमंत कुमार हरमुख
            </a>
            <a>
            <Work/>
               IT Director / हैदराबाद
            </a>
          </Contact>
        </Right>
      </Wrapper>
    </Container>
  );
};

export default Announcement;
