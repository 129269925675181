import { useEffect, useState, useContext } from 'react';
import { navigationLinks } from '../../data';
import styled from 'styled-components';
import { SidebarContext } from './sidebarContext';

// Styled-components

const Container = styled.div`
  background-color: #29221d;
  width: 260px;
  padding: 36px 20px;
  transition: all 300ms ease-in-out;

  &.sidebar-change {
    margin-left: -260px;
  }

  @media screen and (max-width: 1400px) {
    padding: 24px 16px;
  }

  @media screen and (max-width: 1200px) {
    width: 72px;
    padding-left: 12px;
    padding-right: 12px;

    &.sidebar-change {
      margin-left: -72px;
    }
  }

  @media screen and (max-width: 420px) {
    margin-left: -72px;

    &.sidebar-change {
      margin-left: 0px;
    }
  }
`;

const UserInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 16px;

  @media screen and (max-width: 1200px) {
    .info-name {
      display: none;
    }
  }
`;

const InfoImg = styled.div`
  /* width: 48px;
  height: 48px; */
  overflow: hidden;
  border-radius: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  img {
    width: 48px;
  height: 48px;
    /* height: 70px; */
    /* border-radius: 5px; */

    @media (max-width: 488px) {
      height: 70px;
      margin-left:10px;
    }
  }
`;

const InfoName = styled.span`
  font-weight: 500;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
`;

const Navigation = styled.nav`
  margin-top: 28px;
  height: 600px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 18px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fe6c00;
    border-radius: 18px;
  }
`;

const NavItem = styled.li`
  margin-bottom: 10px;
  margin-right: 4px;

  @media screen and (max-width: 1200px) {
    display: flex;
    justify-content: center;
    margin-bottom: 18px;
  }
`;

const NavLink = styled.a`
  display: block;
  height: 44px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 14px;
  column-gap: 12px;
  letter-spacing: 0.03em;
  border: 1px solid transparent;
  transition: all 300ms ease-in-out;

  color: unset;
  text-decoration: none;
  color: white;

  &:hover {
    border: 1px solid #473b33;
  }

  &.active {
    background-color: #fe6c00;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  }

  @media screen and (max-width: 1200px) {
    padding: 0;
    justify-content: center;
    width: 32px;
    height: 32px;

    &.active {
      border-radius: 100%;
    }

    &:hover {
      border-radius: 100%;
    }
  }
`;

const NavLinkIcon = styled.img`
  width: 22px;
`;

const NavLinkText = styled.span`
  text-transform: capitalize;

  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

const Menu = styled.ul`
  list-style-type: none;

`

// Sidebar component
const Sidebar = ({ setActiveContent }) => { // Accept setActiveContent as prop
    const [activeLinkIdx, setActiveLinkIdx] = useState(1);
    const [sidebarClass, setSidebarClass] = useState("");
    const { isSidebarOpen } = useContext(SidebarContext);

    useEffect(() => {
      if (isSidebarOpen) {
        setSidebarClass('sidebar-change');
      } else {
        setSidebarClass('');
      }
    }, [isSidebarOpen]);

    return (
      <Container className={sidebarClass}>
        <UserInfo>
          <InfoImg>
            <img src="/image/kurmi.png" alt="kurmi samaaj Logo" />
          </InfoImg>
          <InfoName>दिल्लीवार कुर्मी क्षत्रिय समाज</InfoName>
        </UserInfo>

        <Navigation>
          <Menu className="nav-list">
            {navigationLinks.map((navigationLink) => (
              <NavItem key={navigationLink.id}>
                <NavLink
                  href="#"
                  onClick={() => {
                    setActiveLinkIdx(navigationLink.id); // Update active link index
                    setActiveContent(navigationLink.title); // Update active content
                  }}
                  className={navigationLink.id === activeLinkIdx ? 'active' : ''}
                >
                  <NavLinkIcon src={navigationLink.image} alt={navigationLink.title} />
                  <NavLinkText>{navigationLink.title}</NavLinkText>
                </NavLink>
              </NavItem>
            ))}
          </Menu>
        </Navigation>
      </Container>
    );
  };

  export default Sidebar;
