import { useState } from "react";
import styled from "styled-components";
import axios from "axios"; // Axios for API requests

// Styled Components
const BiographyContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  background-color: #222; /* Overall background to match dark theme */
  padding: 20px;
  border-radius: 16px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleText = styled.h3`
  font-size: 24px;
  font-weight: bold;
  color: #fff;
`;

const ContentContainer = styled.div`
  margin-top: 20px;
  padding: 12px;
  border-radius: 16px;
  background-color: #363636; /* bg-jet color */
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const Label = styled.label`
  color: #fff;
  margin-bottom: 8px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #444; /* Darker background for input */
  color: #fff;
`;

const TextArea = styled.textarea`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #444;
  color: #fff;
`;

const Button = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;

const UploadContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #444; /* Darker background for the upload section */
  padding: 10px;
  border-radius: 8px;
`;

const UploadText = styled.p`
  color: #fff;
  margin-left: 10px;
`;

const ImagePreview = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 10px;
`;

const RemoveButton = styled.button`
  margin-left: 10px;
  background-color: #ff4d4d; /* Red color for remove button */
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #e60000;
  }
`;

const SuccessMessage = styled.p`
  color: #4caf50; /* Success green color */
  font-weight: bold;
  margin-top: 20px;
`;

const Biography = () => {
  const [biographyData, setBiographyData] = useState({
    name: "",
    img: null,
    description: "",
    lifespan: "",
    author: "",  // Ensure author is included
  });

  const [previewUrl, setPreviewUrl] = useState(null);
  const [successMessage, setSuccessMessage] = useState(""); // New state for success message

  const handleChange = (e) => {
    setBiographyData({
      ...biographyData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setBiographyData({
      ...biographyData,
      img: file, // Handling image upload
    });
    setPreviewUrl(URL.createObjectURL(file)); // Preview image before upload
  };

  const handleRemoveImage = () => {
    setBiographyData({
      ...biographyData,
      img: null, // Clear the image file
    });
    setPreviewUrl(null); // Clear the preview URL
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate that all required fields are filled
    if (!biographyData.name || !biographyData.description || !biographyData.lifespan) {
      console.error("Please fill out all required fields.");
      return; // Prevent submission if fields are missing
    }

    console.log("Submitting Biography Data:", biographyData); // Check if all values are correctly set

    const formData = new FormData();
    formData.append("name", biographyData.name);
    formData.append("img", biographyData.img);
    formData.append("description", biographyData.description);
    formData.append("lifespan", biographyData.lifespan);
    formData.append("author", biographyData.author);  // Ensure author is included

    try {
      // const response = await axios.post("http://localhost:5000/api/biography", formData, {
      const response = await axios.post("https://kurmi-samaj.onrender.com/api/biography", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Biography uploaded successfully", response.data);

      // Show success message and reset the form fields
      setSuccessMessage("Biography uploaded successfully!");
      setBiographyData({ name: "", img: null, description: "", lifespan: "", author: "" });
      setPreviewUrl(null);
    } catch (error) {
      console.error("Error uploading biography:", error);
      setSuccessMessage("Error uploading biography. Please try again.");
    }
  };

  return (
    <BiographyContainer>
      <TitleContainer>
        <TitleText>Add New Biography</TitleText>
      </TitleContainer>

      <ContentContainer>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Name</Label>
            <Input
              type="text"
              name="name"
              value={biographyData.name}
              onChange={handleChange}
              placeholder="Enter name"
            />
          </FormGroup>

          <FormGroup>
            <Label>Image</Label>
            <UploadContainer>
              {previewUrl && (
                <>
                  <ImagePreview src={previewUrl} alt="Preview" />
                  <RemoveButton type="button" onClick={handleRemoveImage}>
                    Remove
                  </RemoveButton>
                </>
              )}
              <Input
                type="file"
                name="img"
                onChange={handleFileChange}
                accept="image/*"
                required={!previewUrl} // Required if no image is uploaded
              />
              <UploadText>{biographyData.img ? biographyData.img.name : "Choose a file"}</UploadText>
            </UploadContainer>
          </FormGroup>

          <FormGroup>
            <Label>Description</Label>
            <TextArea
              name="description"
              value={biographyData.description}
              onChange={handleChange}
              rows="5"
              placeholder="Enter biography description"
              required
            />
          </FormGroup>

          <FormGroup>
            <Label>Lifespan</Label>
            <Input
              type="text"
              name="lifespan"
              value={biographyData.lifespan}
              onChange={handleChange}
              placeholder="e.g., 1918 - 1968"
              required
            />
          </FormGroup>

          <FormGroup>
            <Label>Author</Label>
            <Input
              type="text"
              name="author"
              value={biographyData.author}
              onChange={handleChange}
              placeholder="Enter author name"
              required
            />
          </FormGroup>

          <Button type="submit">Submit Biography</Button>
        </form>

        {/* Show success message after form submission */}
        {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
      </ContentContainer>
    </BiographyContainer>
  );
};

export default Biography;
