import { useState } from "react";
import styled from "styled-components";
import axios from "axios"; // Axios for API requests

// Styled Components
const NewsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  background-color: #222; /* Overall background to match dark theme */
  padding: 20px;
  border-radius: 16px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleText = styled.h3`
  font-size: 24px;
  font-weight: bold;
  color: #fff;
`;

const ContentContainer = styled.div`
  margin-top: 20px;
  padding: 12px;
  border-radius: 16px;
  background-color: #363636; /* bg-jet color */
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const Label = styled.label`
  color: #fff;
  margin-bottom: 8px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #444; /* Darker background for input */
  color: #fff;
`;

const TextArea = styled.textarea`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #444;
  color: #fff;
`;

const Button = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;

const UploadContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #444; /* Darker background for the upload section */
  padding: 10px;
  border-radius: 8px;
`;

const UploadText = styled.p`
  color: #fff;
  margin-left: 10px;
`;

const ImagePreview = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 10px;
`;

const RemoveButton = styled.button`
  margin-left: 10px;
  background-color: #ff4d4d; /* Red color for remove button */
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #e60000;
  }
`;

const NewsUpload = () => {
  const [newsData, setNewsData] = useState({
    title: "",
    img: null,
    description: "",
    url: "",
    publishedAt: "",
  });

  const [previewUrl, setPreviewUrl] = useState(null);

  const handleChange = (e) => {
    setNewsData({
      ...newsData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setNewsData({
      ...newsData,
      img: file, // Handling image upload
    });
    setPreviewUrl(URL.createObjectURL(file)); // Preview image before upload
  };

  const handleRemoveImage = () => {
    setNewsData({
      ...newsData,
      img: null, // Clear the image file
    });
    setPreviewUrl(null); // Clear the preview URL
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", newsData.title);
    formData.append("img", newsData.img);
    formData.append("description", newsData.description);
    formData.append("url", newsData.url);
    formData.append("publishedAt", newsData.publishedAt);

    try {
      const response = await axios.post("http://localhost:5000/api/news", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("News uploaded successfully", response.data);
    } catch (error) {
      console.error("Error uploading news:", error);
    }
  };

  return (
    <NewsContainer>
      <TitleContainer>
        <TitleText>Add New News</TitleText>
      </TitleContainer>

      <ContentContainer>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Title</Label>
            <Input
              type="text"
              name="title"
              value={newsData.title}
              onChange={handleChange}
              placeholder="Enter news title"
              required
            />
          </FormGroup>

          <FormGroup>
            <Label>Image</Label>
            <UploadContainer>
              {previewUrl && (
                <>
                  <ImagePreview src={previewUrl} alt="Preview" />
                  <RemoveButton type="button" onClick={handleRemoveImage}>
                    Remove
                  </RemoveButton>
                </>
              )}
              <Input
                type="file"
                name="img"
                onChange={handleFileChange}
                accept="image/*"
                required={!previewUrl} // Required if no image is uploaded
              />
              <UploadText>{newsData.img ? newsData.img.name : "Choose a file"}</UploadText>
            </UploadContainer>
          </FormGroup>

          <FormGroup>
            <Label>Description</Label>
            <TextArea
              name="description"
              value={newsData.description}
              onChange={handleChange}
              rows="5"
              placeholder="Enter news description"
              required
            />
          </FormGroup>

          <FormGroup>
            <Label>External URL</Label>
            <Input
              type="text"
              name="url"
              value={newsData.url}
              onChange={handleChange}
              placeholder="Enter external URL"
              required
            />
          </FormGroup>

          <FormGroup>
            <Label>Published Date</Label>
            <Input
              type="datetime-local"
              name="publishedAt"
              value={newsData.publishedAt}
              onChange={handleChange}
              required
            />
          </FormGroup>

          <Button type="submit">Submit News</Button>
        </form>
      </ContentContainer>
    </NewsContainer>
  );
};

export default NewsUpload;
