import { useSelector } from "react-redux";
import Cart from "./pages/Cart";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Product from "./pages/Product";
import { ProductList } from "./pages/ProductList";
import Register from "./pages/Register";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Biography from "./pages/Biography";
import PhotoGallery from "./pages/PhotoGallery";
import PhotoCollection from "./pages/PhotoCollection";
import News from "./pages/News";
import Dashboard from "./pages/Dashboard";
import ProtectedRoute from "./components/Dashboard/ProtectedRoute";


const App = () => {
  const user = useSelector((state) => state.user.currentUser);
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/products/:category" element={<ProductList />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/biography" element={<Biography/>} />
        <Route path="/gallery" element={<PhotoGallery/>} />
        <Route path="/gallery/:title" element={<PhotoCollection/>} />
        <Route path="/login" element={user ? <Navigate to="/dashboard" /> : <Login />} />
        <Route path="/register" element={user ? <Navigate to="/" /> : <Register />} />
        <Route path="/news" element={<News/>} />
        {/* <Route path="/dashboard" element={<Dashboard/>} /> */}

        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;