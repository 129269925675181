import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Announcement from "../components/Announcement";
import Navbar1 from "../components/Navbar1";

// Modal styles
const ModalOverlay = styled.div`
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalContent = styled.div`
  position: relative;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 10px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 30px;
  font-weight:800;
  color: white;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  min-height: 100vh;
`;

const Title = styled.h1`
  margin-top: 20px;
  font-size: 32px;
  color: #222;
  font-weight: 700;
  text-align: center;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 10%;
  width: 100%;
  max-width: 1000px;
  padding: 0 20px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const PhotoCard = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #fff;

  &:hover {
    transform: scale(1.03);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
`;

const PhotoImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
`;

const hardcodedPhotos = {
  "54वें वार्षिक अधिवेशन": [
    { id: 1, img: "./image/54वें वार्षिक अधिवेशन.jpg" },
    // { id: 2, img: "./image/nature2.jpg" },
    // { id: 3, img: "./image/nature3.jpg" },
  ],
  "53वाँ वार्षिक अधिवेशन": [
    { id: 1, img: "./image/124.jpg" },
    // { id: 2, img: "./image/wildlife1.jpg" },
    // { id: 3, img: "./image/wildlife3.jpg" },
  ],
  "51वां अधिवेशन": [
    { id: 1, img: "./image/125.jpg" },
    // { id: 2, img: "./image/cityscape2.jpg" },
    // { id: 3, img: "./image/cityscape3.jpg" },
  ],
  // Mountains: [
  //   { id: 1, img: "./image/mountain1.jpg" },
  //   { id: 2, img: "./image/mountain2.jpg" },
  //   { id: 3, img: "./image/mountain3.jpg" },
  // ],
  // Ocean: [
  //   { id: 1, img: "./image/ocean1.jpg" },
  //   { id: 2, img: "./image/ocean2.jpg" },
  //   { id: 3, img: "./image/ocean3.jpg" },
  // ],
  // Forest: [
  //   { id: 1, img: "./image/forest1.jpg" },
  //   { id: 2, img: "./image/forest2.jpg" },
  //   { id: 3, img: "./image/forest3.jpg" },
  // ],
};

const PhotoCollection = () => {
  const { title } = useParams();
  const [relatedPhotos, setRelatedPhotos] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [selectedImage, setSelectedImage] = useState(null); // Selected image state

  useEffect(() => {
    if (hardcodedPhotos[title]) {
      setRelatedPhotos(hardcodedPhotos[title]);
    }
  }, [title]);

  // Function to open the modal and set the clicked image
  const openModal = (img) => {
    setSelectedImage(img);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  return (
    <Container>
      {!isModalOpen && <Announcement />}
      {!isModalOpen && <Navbar1 />}
      <Title>{title} Collection</Title>
      <Wrapper>
        {relatedPhotos.map((photo) => (
          <PhotoCard key={photo.id} onClick={() => openModal(photo.img)}>
            <PhotoImage src={photo.img} alt={title} />
          </PhotoCard>
        ))}
      </Wrapper>

      {/* Modal for enlarged image */}
      {isModalOpen && (
        <ModalOverlay onClick={closeModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <ModalImage src={selectedImage} alt="Enlarged View" />
            <CloseButton onClick={closeModal}>&times;</CloseButton>
          </ModalContent>
        </ModalOverlay>
      )}
    </Container>
  );
};

export default PhotoCollection;
