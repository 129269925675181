import { Search, Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material';
import React, { useState } from 'react';
import styled from 'styled-components';
import { mobile } from "../responsive";
import { Link } from 'react-router-dom';

const Containers = styled.div`
  height: 80px;
  position: fixed;
  top: 90px;
  width: 100%;
  margin-bottom: 5px;
  background-color: white;
  z-index: 1000;
  ${mobile({
    height: "50px"
  })};
  @media (max-width: 488px) {
    position:absolute;
    height: 60px;
    top: 90;
  }
`;

const Wrapper = styled.div`
  padding: 12px 20px;
  /* top:99px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 488px) {
    padding: 10px 0;
    top:99px;
  }
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const SearchContainer = styled.div`
  border: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  margin-left: 25px;
  padding: 5px 13px;
  border-radius: 50px;
`;

const Input = styled.input`
  border: none;
  outline: none;
  ${mobile({
    width: "50px;"
  })};
`;

const Logo = styled.div`
  img {
    height: 60px;
    border-radius: 5px;
    @media (max-width: 488px) {
      height: 50px;
      margin-left:10px;
    }
  }
  @media (max-width: 488px) {
    display: none;
  }
`;

const Center = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 488px) {
    display: none; /* Hide the menu items on mobile */
  }
`;

const MenuItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  margin-left: 25px;
  @media (max-width: 488px) {
    font-size: 15px;
    margin-left: 10px;
  }
  &:hover {
    font-weight: bold;
    color: #bf4625;
  }
`;

const DropdownMenu = styled.div`
  display: ${props => (props.open ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  /* left: 0; */
  background-color: white;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  z-index: 1;
  min-width: 160px;
  /* padding: 12px 16px; */
  text-align: left;
`;

const DropdownItem = styled(Link)`
  color: black;
  padding: 8px 12px;
  text-decoration: none;
  display: block;
  font-weight: normal;
  &:hover {
    background-color: #f1f1f1;
    font-weight: bold;
    color: #bf4625;
  }
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 488px) {
    display: none; /* Hide the menu items on mobile */
  }
`;

const HamburgerMenu = styled.div`
  display: none;
  margin-right: 10px;

  svg{
    font-size: 36px;
  }
  @media (max-width: 488px) {
    display: block;
    cursor: pointer;
  }
`;

const MobileMenu = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 1000;
  display: ${(props) => (props.open ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 20px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (min-width: 489px) {
    display: none;
  }
`;

const MobileMenuItem = styled.div`
  margin: 10px 0;
  font-size: 18px;
  cursor: pointer;
  &:hover {
    font-weight: bold;
    color: #bf4625;
  }
`;

const Navbar1 = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);


  const handleMouseEnter = (menu) => {
    setOpenDropdown(menu);
  }

  const handleMouseLeave = () => {
    setOpenDropdown(null);
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Containers>
      <Wrapper>
        <Left>
          <Link to="/">
            <Logo>
              <img src="/image/kurmi.png" alt="kurmi samaaj Logo" />
            </Logo>
          </Link>
          <SearchContainer>
            <Input placeholder='यहाँ कुछ खोजें...' />
            <Search />
          </SearchContainer>
        </Left>

        {/* Center and Right Menu for Desktop */}
        <Center>
          <Link to="/biography" style={{ textDecoration: 'none', color: 'black' }}>
            <MenuItem>जीवन परिचय</MenuItem>
          </Link>
          {/* <Link to="/gallery" style={{ textDecoration: 'none', color: 'black' }}>
            <MenuItem>सामाजिक पदाधिकारी</MenuItem>
          </Link> */}
          {/* <Link to="/gallery" style={{ textDecoration: 'none', color: 'black' }}>
            <MenuItem> सर्किल एवं नगर इकाई</MenuItem>
          </Link> */}
          <MenuItem
            onMouseEnter={() => handleMouseEnter('सामाजिक पदाधिकारी')}
            onMouseLeave={handleMouseLeave}
          >
            <span>सामाजिक पदाधिकारी</span>
            {openDropdown === 'सामाजिक पदाधिकारी'}
            <DropdownMenu open={openDropdown === 'सामाजिक पदाधिकारी'}>
              <DropdownItem to="/">केंद्रीय कार्यकारणी महिला सदस्य</DropdownItem>
              <DropdownItem to="/">केंद्रीय कार्यकारणी युवा सदस्य</DropdownItem>
            </DropdownMenu>
          </MenuItem>

          <MenuItem
            onMouseEnter={() => handleMouseEnter('सर्किल एवं नगर इकाई')}
            onMouseLeave={handleMouseLeave}
          >
            <span>सर्किल एवं नगर इकाई</span>
            {openDropdown === 'सामाजिक पदाधिकारी'}
            <DropdownMenu open={openDropdown === 'सर्किल एवं नगर इकाई'}>
              <DropdownItem to="/">13 सर्किल</DropdownItem>
              <DropdownItem to="/">5 नगर इकाई</DropdownItem>
            </DropdownMenu>
          </MenuItem>
          {/* <Link to="/gallery" style={{ textDecoration: 'none', color: 'black' }}>
            <MenuItem>गैलरी</MenuItem>
          </Link>
          <Link to="/news" style={{ textDecoration: 'none', color: 'black' }}>
            <MenuItem>समाचार</MenuItem>
          </Link> */}
        </Center>

        <Right>

          <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
            <MenuItem>मुख पृष्ठ</MenuItem>
          </Link>
        <Link to="/gallery" style={{ textDecoration: 'none', color: 'black' }}>
            <MenuItem>गैलरी</MenuItem>
          </Link>
          <Link to="/news" style={{ textDecoration: 'none', color: 'black' }}>
            <MenuItem>समाचार</MenuItem>
          </Link>
          <Link to="/login" style={{ textDecoration: 'none', color: 'black' }}>
            <MenuItem>लॉग इन</MenuItem>
          </Link>

          {/* <Link to="/register" style={{ textDecoration: 'none', color: 'black' }}>
            <MenuItem>रजिस्टर करे</MenuItem>
          </Link> */}

        </Right>

        {/* Hamburger Menu for Mobile */}
        <HamburgerMenu onClick={toggleMenu}>
          {menuOpen ? <CloseIcon /> : <MenuIcon />}
        </HamburgerMenu>
      </Wrapper>

      {/* Mobile Dropdown Menu */}
      <MobileMenu open={menuOpen}>
        <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
          <MobileMenuItem onClick={toggleMenu}>मुख पृष्ठ</MobileMenuItem>
        </Link>
        <Link to="/biography" style={{ textDecoration: 'none', color: 'black' }}>
          <MobileMenuItem onClick={toggleMenu}>जीवन परिचय</MobileMenuItem>
        </Link>
        <Link to="/news" style={{ textDecoration: 'none', color: 'black' }}>
          <MobileMenuItem onClick={toggleMenu}>समाचार</MobileMenuItem>
        </Link>
        <Link to="/gallery" style={{ textDecoration: 'none', color: 'black' }}>
          <MobileMenuItem onClick={toggleMenu}>गैलरी</MobileMenuItem>
        </Link>
        <Link to="/login" style={{ textDecoration: 'none', color: 'black' }}>
          <MobileMenuItem onClick={toggleMenu}>लॉग इन</MobileMenuItem>
        </Link>
        {/* <Link to="/register" style={{ textDecoration: 'none', color: 'black' }}>
          <MobileMenuItem onClick={toggleMenu}>रजिस्टर करे</MobileMenuItem>
        </Link> */}
      </MobileMenu>
    </Containers>
  );
};

export default Navbar1;
