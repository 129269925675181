import Biography from "../Biography/Biography";
import News from "../News/News";
// import News from "../News/News"; // Import other components for the respective pages
// import Subscriptions from "../Subscriptions/Subscriptions";
// import Gallery from "../Gallery/Gallery";
// import Settings from "../Settings/Settings";

const ContentMain = ({ activeContent }) => {
  return (
    <div className="main-content-holder">
      <div className="content-grid-one">
        {
          // Conditionally render components based on the activeContent
          activeContent === 'Home' && <Biography />
        }
        {
          activeContent === 'Biography' && <Biography />
        }
        {
          activeContent === 'News' && <News />
        }
        {/* {
          activeContent === 'Subscriptions' && <Subscriptions />
        }
        {
          activeContent === 'Gallery' && <Gallery />
        }
        {
          activeContent === 'Settings' && <Settings />
        } */}
      </div>
    </div>
  );
};

export default ContentMain;
