import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ children }) => {
  const user = useSelector((state) => state.user.currentUser);

  // If no user is found, redirect to the login page
  return user ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
