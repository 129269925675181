import styled from "styled-components";
import ContentMain from "../ContentMain/ContentMain";

const Container = styled.div`
  background-color: #473b33;
  flex: 1;
  padding: 32px;
`;

const Content = ({ activeContent }) => {
  return (
    <Container>
      <ContentMain activeContent={activeContent} /> {/* Pass active content to ContentMain */}
    </Container>
  );
};

export default Content;
