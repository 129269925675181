// icons svg images
import bills from "../assets/icons/bills.svg";
import budget from "../assets/icons/budget.svg";
import card from "../assets/icons/card.svg";
import check from "../assets/icons/check.svg";
import empty_check from "../assets/icons/empty_check.svg";
import gears from "../assets/icons/gears.svg";
import home from "../assets/icons/home.svg";
import menu from "../assets/icons/menu.svg";
import plane from "../assets/icons/plane.svg";
import plus from "../assets/icons/plus.svg";
import report from "../assets/icons/report.svg";
import search from "../assets/icons/search.svg";
import user from "../assets/icons/user.svg";
import wallet from "../assets/icons/wallet.svg";
import logout from "../assets/icons/logout.svg";

export const iconsImgs = {
      bills, budget, card, check, empty_check, gears, home, menu, plane, plus, report, search, user, wallet,logout
}


