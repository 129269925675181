import styled from "styled-components";
import { useState } from "react";
import Sidebar from "../components/Dashboard/Sidebar";
import Content from "../components/Dashboard/Content/Content";

const Container = styled.div`
  display: flex;
`;

const Dashboard = () => {
  // State to track the currently selected link from the sidebar
  const [activeContent, setActiveContent] = useState('Home'); // Default is 'Home'

  return (
    <Container>
      <Sidebar setActiveContent={setActiveContent} /> {/* Pass the setter to Sidebar */}
      <Content activeContent={activeContent} /> {/* Pass the active content to Content */}
    </Container>
  );
};

export default Dashboard;
